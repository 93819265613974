export default {
  "container": "cIl",
  "mapped": "cIX",
  "header": "cIC",
  "title": "cIk caption",
  "select-option-label": "cIJ",
  "content": "cIs",
  "item": "cIF body-2",
  "empty": "cIg"
};
