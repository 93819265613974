export default {
  "row": "cln",
  "terminated": "cli",
  "status-avatar": "clA",
  "amount": "clY body-1",
  "item-info": "clh",
  "date-cell": "clf",
  "cell": "clK body-2",
  "status-icon": "clG",
  "amount-cell": "clr",
  "item-name-cell": "clb",
  "item-name-container": "cly"
};
