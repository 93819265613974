export default {
  "wrapper": "ckW",
  "cashflow-status": "cku",
  "status": "cJS",
  "estimated": "cJc",
  "vat-due-amount": "cJq title-3",
  "vat-tooltip": "cJZ",
  "icon-info": "cJR",
  "charts-wrapper": "cJQ",
  "chart-error": "cJe",
  "chart-controls": "cJB",
  "amount-loading": "cJE"
};
