export default {
  "row": "cFH",
  "cell": "cFO body-1",
  "row-cancelled": "cFm",
  "cell-amount": "cFp",
  "avatar": "cFx",
  "empty": "cFw",
  "align-right": "cFN",
  "debitor-container": "cFv",
  "debitor-info": "cFo",
  "debitor-name": "cFn body-1",
  "with-sidebar": "cFi",
  "method-col": "cFA",
  "resource-number": "cFY caption",
  "resource-icon": "cFh"
};
