export default {
  "wrapper": "cJL",
  "header": "cJT",
  "fadeIn": "cJz",
  "header-content": "cJa",
  "with-cta": "cJH",
  "header-title": "cJO title-4",
  "advanced-filters-trigger": "cJm",
  "filters-button": "cJp",
  "filters-button-upsell": "cJx",
  "filters-upsell-item": "cJw",
  "filters-badge": "cJN",
  "chart-container": "cJv",
  "chart-legend-container": "cJo mb-24",
  "chart-watermark-container": "cJn",
  "chart-watermark": "cJi",
  "placeholder-title": "cJA",
  "placeholder-subtitle": "cJY",
  "advanced-filters": "cJh"
};
