export default {
  "wrapper": "cse",
  "header": "csB",
  "fadeIn": "csE",
  "header-title": "csd title-4",
  "transactions-container": "csI",
  "placeholder-title": "cst",
  "transaction-item-list-container": "csM",
  "transaction-item-list": "csP",
  "item": "csl"
};
