export default {
  "invitations-password-form": "cEc",
  "mobile-mode": "cEq",
  "logo": "cEZ",
  "header": "cER",
  "controls": "cEQ",
  "footer": "cEe",
  "submit-cta": "cEB",
  "subtitle": "cEE"
};
