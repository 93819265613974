export default {
  "row": "cVS",
  "cell": "cVc",
  "title": "cVq",
  "internal-note": "cVZ",
  "unit": "cVR",
  "price": "cVQ",
  "quick-actions": "cVe",
  "missing-details-container": "cVB",
  "missing-details-label": "cVE body-2",
  "warning-icon": "cVd",
  "empty": "cVI",
  "checkbox": "cVt",
  "align-checkbox": "cVM"
};
