export default {
  "details-box": "clH",
  "picto": "clO",
  "picto-status": "clm",
  "ghost": "clp",
  "primary-info": "clx",
  "date": "clw",
  "account-infobox": "clN",
  "declined-disclaimer": "clv",
  "nrc-download": "clo"
};
