export default {
  "notifications-settings": "cPU",
  "opened": "cPj",
  "icon": "cPW",
  "header": "cPu",
  "header-toggle": "clS",
  "avatar-image": "clc",
  "title": "clq",
  "icon--right": "clZ",
  "toggle-container": "clR",
  "toggle": "clQ"
};
