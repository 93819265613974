export default {
  "container": "cEU",
  "form-container": "cEj",
  "title": "cEW",
  "title-border": "cEu",
  "tabs-container": "cdS",
  "preview-container": "cdc",
  "email-preview-container": "cdq",
  "close-button": "cdZ btn btn--icon-only btn--tertiary btn--large"
};
