export default {
  "option": "cgz",
  "account-description": "cga",
  "account-identification": "cgH",
  "account-avatar": "cgO",
  "account-name": "cgm body-2",
  "account-balance": "cgp body-2",
  "external-accounts-disclaimer": "cgx",
  "status": "cgw body-2",
  "failed-connection": "cgN"
};
