export default {
  "custom-period-wrapper": "cCo",
  "fadeIn": "cCn",
  "custom-period-description": "cCi body-2",
  "header": "cCA",
  "arrow-left": "cCY",
  "body": "cCh",
  "period-wrapper": "cCf",
  "back-button": "cCK",
  "footer": "cCG"
};
