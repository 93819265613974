export default {
  "bucket-item": "cQD",
  "button": "cQV",
  "content": "cQL",
  "bucket-icon": "cQT",
  "bucket-title-container": "cQz",
  "bucket-new-badge": "cQa",
  "bucket-title": "cQH",
  "arrow-icon": "cQO"
};
