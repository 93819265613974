export default {
  "name-wrapper": "cFG",
  "preset-name": "cFr",
  "invoices-entry-period-container": "cFb",
  "transactions-entry-period-container": "cFy",
  "period-wrapper": "cFU",
  "period-btn": "cFj",
  "cancel-placeholder": "cFW",
  "period-cancel-btn": "cFu",
  "transactions-entry-container": "cgS",
  "invoices-entry-container": "cgc",
  "dropdown": "cgq",
  "dropdown-opened": "cgZ",
  "dropdown-closed": "cgR"
};
