export default {
  "wrapper": "cBJ",
  "mobile-mode": "cBs",
  "logo": "cBF",
  "address-form": "cBg",
  "button-container": "cBD",
  "address-form-header": "cBV",
  "address-form-content": "cBL",
  "address-form-address-search": "cBT",
  "ember-basic-dropdown": "cBz",
  "address-form-footer": "cBa",
  "submit-cta": "cBH",
  "form": "cBO",
  "city-section": "cBm",
  "title": "cBp",
  "mobile-title": "cBx"
};
