export default {
  "container": "cej",
  "header": "ceW",
  "content": "ceu",
  "info-list": "cBS",
  "info-label": "cBc body-2",
  "info-value": "cBq body-2",
  "provider": "cBZ",
  "policy-container": "cBR",
  "policy-label": "cBQ body-2",
  "policy-number": "cBe body-2",
  "copy-to-clipboard": "cBB",
  "button": "cBE btn btn--primary btn--stretch btn--large"
};
