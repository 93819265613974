export default {
  "section-item": "cCb",
  "sm": "cCy",
  "xs": "cCU",
  "section-item-label": "cCj body-2",
  "section-item-value": "cCW body-2",
  "positive": "cCu",
  "negative": "ckS",
  "hidden": "ckc",
  "section-item-caption": "ckq caption"
};
