export default {
  "card": "cXs",
  "header": "cXF",
  "avatar": "cXg",
  "title": "cXD",
  "subtitle": "cXV",
  "label": "cXL",
  "item": "cXT body-2",
  "header-icon": "cXz",
  "mt-8": "cXa"
};
