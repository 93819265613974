export default {
  "body": "cMo",
  "members": "cMn",
  "members-results": "cMi",
  "members-list": "cMA",
  "members-title": "cMY caption-bold",
  "member": "cMh",
  "empty-illustration": "cMf",
  "empty-title": "cMK title-3",
  "empty-description": "cMG body-2",
  "invitable-members": "cMr",
  "details": "cMb",
  "pagination-footer": "cMy"
};
