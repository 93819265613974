export default {
  "sidebar-section": "cex body-2",
  "sidebar-section-header": "cew body-1 mb-4",
  "providers-logos": "ceN",
  "risks": "cev",
  "tag": "ceo tag",
  "external-link-icon": "cen",
  "disclaimer": "cei",
  "divider": "ceA"
};
