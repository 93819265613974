export default {
  "company-logo": "cPi",
  "list-item": "cPA",
  "link": "cPY",
  "title-wrapper": "cPh",
  "title": "cPf body-2",
  "subtitle": "cPK caption",
  "truncated-text": "cPG",
  "image-placeholder": "cPr"
};
