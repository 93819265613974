export default {
  "header": "cdJ",
  "center": "cds",
  "header-placeholder": "cdF",
  "placeholder": "cdg",
  "name-block": "cdD",
  "block": "cdV",
  "detail-block": "cdL",
  "border-top": "cdT"
};
