export default {
  "donut-chart-container": "cXr",
  "donut-chart-wrapper": "cXb",
  "chart": "cXy",
  "donut-chart-legend-container": "cXU caption",
  "donut-chart-legend-item-empty": "cXj",
  "donut-chart-legend-bullet-empty": "cXW",
  "donut-chart-legend-bullet": "cXu",
  "donut-chart-legend-text-empty": "cCS",
  "donut-chart-legend-text": "cCc",
  "donut-chart-custom-label-uncategorized": "cCq",
  "custom-label-uncategorized-button": "cCZ body-1",
  "donut-chart-legend": "cCR",
  "donut-chart-legend-item": "cCQ",
  "donut-chart-legend-other-item": "cCe",
  "donut-chart-legend-uncategorized-item": "cCB",
  "donut-chart-legend-text-name": "cCE",
  "tooltip": "cCd"
};
