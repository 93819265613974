export default {
  "card": "cIh",
  "focus": "cIf",
  "title-format": "cIK",
  "header": "cIG",
  "headings": "cIr",
  "title": "cIb title-4",
  "main": "cIy title-3",
  "footer": "cIU"
};
