export default {
  "item": "cMJ",
  "selected": "cMs",
  "hover-revised": "cMF",
  "asset-container": "cMg",
  "asset": "cMD",
  "revoked-icon": "cMV",
  "pending-icon": "cML",
  "revoked": "cMT",
  "revised-revoke": "cMz",
  "pending": "cMa",
  "revised-pending": "cMH",
  "invitable": "cMO",
  "info": "cMm",
  "name": "cMp",
  "email": "cMx",
  "actions": "cMw",
  "invitable-member-delete": "cMN",
  "invitable-member-invite": "cMv"
};
