export default {
  "account-container": "csw",
  "badge": "csN",
  "subtitle": "csv title-4",
  "accounts-filter": "cso",
  "account-filter-label": "csn",
  "status": "csi",
  "account-info": "csA",
  "balance": "csY",
  "revealed-balance": "csh",
  "hidden-balance": "csf",
  "mr-12": "csK",
  "disabled-selector": "csG"
};
