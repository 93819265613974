export default {
  "widgets-container": "ckh",
  "subtitle-container": "ckf",
  "indicator-container": "ckK",
  "transactions-container": "ckG",
  "indicator": "ckr",
  "cashflow-container": "ckb",
  "cashflow": "cky",
  "donut-container": "ckU",
  "donut": "ckj"
};
