export default {
  "file-uploader": "cVs",
  "file-uploader-content": "cVF",
  "file-uploader-dropzone": "cVg",
  "fixedSize": "cVD",
  "two-column": "cVV",
  "file-uploader-label": "cVL body-1",
  "required-chip": "cVT",
  "animated-container": "cVz",
  "toggle-button": "cVa",
  "toggle-button-icon": "cVH"
};
