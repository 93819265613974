export default {
  "header-cell": "cdG",
  "empty": "cdr",
  "header-content": "cdb caption-bold",
  "active": "cdy",
  "align-right": "cdU",
  "first-col": "cdj",
  "mid-col": "cdW",
  "status-col": "cdu"
};
