export default {
  "fullscreen-modal": "cDC",
  "creation-form-modal": "cDk",
  "links-container": "cDJ",
  "link-field": "cDs",
  "link": "cDF",
  "add-link": "cDg",
  "remove-link": "cDD",
  "flex-row": "cDV",
  "flex-item": "cDL",
  "unit-trigger": "cDT",
  "dynamic-placeholders": "cDz"
};
