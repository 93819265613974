export default {
  "header": "cFP",
  "top-section": "cFl",
  "close": "cFX btn btn--icon-only btn--tertiary",
  "container": "cFC",
  "picto": "cFk",
  "details": "cFJ",
  "expiration-date": "cFs",
  "payment-method": "cFF"
};
