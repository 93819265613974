export default {
  "header-cell": "cDY",
  "checkbox-cell": "cDh",
  "title-col": "cDf",
  "internal-note-col": "cDK",
  "unit-col": "cDG",
  "price-col": "cDr",
  "header-content": "cDb caption-bold",
  "active": "cDy",
  "empty": "cDU",
  "align-checkbox": "cDj"
};
