export default {
  "integration-item": "cQv",
  "icon-container": "cQo",
  "content": "cQn",
  "title-container": "cQi",
  "new-badge": "cQA",
  "title": "cQY",
  "description": "cQh",
  "no-attributes": "cQf",
  "connect-cta": "cQK"
};
