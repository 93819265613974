export default {
  "card": "cXE",
  "header": "cXd",
  "avatar": "cXI",
  "title": "cXt",
  "subtitle": "cXM",
  "label": "cXP",
  "item": "cXl body-2",
  "header-icon": "cXX",
  "dropdown-menu": "cXC",
  "menu-item": "cXk body-2",
  "delete": "cXJ"
};
