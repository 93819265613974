export default {
  "item-status": "cJf",
  "item-date": "cJK",
  "item-amount": "cJG body-1",
  "item-amount-credit": "cJr",
  "row": "cJb",
  "icon": "cJy",
  "disabled": "cJU",
  "icon-container": "cJj",
  "text-container": "cJW",
  "text-row": "cJu",
  "item-details": "csS",
  "counterparty-name": "csc",
  "column": "csq",
  "item-amount-disabled": "csZ",
  "attachment-tooltip": "csR",
  "bank-account-avatar": "csQ"
};
