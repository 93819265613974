export default {
  "wrapper": "ceL",
  "content": "ceT",
  "back": "cez btn btn--tertiary",
  "heading": "cea",
  "heading-content": "ceH",
  "logo": "ceO",
  "description": "cem body-2",
  "cta": "cep"
};
