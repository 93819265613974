export default {
  "mandate-list-item": "ctg",
  "selected": "ctD",
  "status-avatar": "ctV",
  "infos": "ctL",
  "info-title": "ctT body-1",
  "info-title-suspended": "ctz",
  "info-title-status-suspended": "cta",
  "info-mandate-count": "ctH body-2"
};
