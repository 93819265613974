export default {
  "fieldset": "cEV",
  "numbering-format": "cEL",
  "numbering-fields": "cET",
  "input-settings": "cEz",
  "label-tooltip": "cEa",
  "tooltip-icon": "cEH",
  "next-invoice-number": "cEO",
  "spacing": "cEm"
};
