export default {
  "navigation-dropdown": "cPF",
  "expanded": "cPg",
  "trigger": "cPD",
  "trigger-title-icon": "cPV",
  "collapsed": "cPL",
  "responsive": "cPT",
  "logo": "cPz",
  "active": "cPa",
  "trigger-title": "cPH",
  "content": "cPO",
  "content-container": "cPm",
  "dropdown-icon": "cPp",
  "link": "cPx"
};
