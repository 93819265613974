export default {
  "y-axis-placeholder": "cXp",
  "y-axis-navigable": "cXx",
  "y-axis-tick": "cXw",
  "x-axis-placeholder": "cXN",
  "x-axis-navigable": "cXv",
  "x-axis-tick-wrapper": "cXo",
  "x-axis-tick": "cXn",
  "chart-loading-renderer": "cXi"
};
