export default {
  "members-loading": "cPZ",
  "members-loading--ff": "cPR",
  "header": "cPQ",
  "header-search-bar": "cPe",
  "body": "cPB",
  "members": "cPE",
  "placeholder-container": "cPd",
  "icon": "cPI",
  "details": "cPt",
  "member-loading": "cPM",
  "member-loading--no-ff": "cPP",
  "body-members": "cPl",
  "body-details": "cPX"
};
