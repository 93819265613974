export default {
  "sidebar": "cdz",
  "top-section": "cda",
  "title": "cdH",
  "primary-action": "cdO",
  "edit-icon": "cdm",
  "close-icon": "cdp",
  "box-wrapper": "cdx",
  "bottom-action": "cdw",
  "related-invoices": "cdN",
  "related-invoices-title": "cdv mb-16 caption-bold",
  "box": "cdo",
  "box-element": "cdn",
  "cancel-text": "cdi",
  "box-header": "cdA",
  "row": "cdY",
  "greyed-out": "cdh",
  "struck-through": "cdf",
  "share-mandate-link": "cdK"
};
