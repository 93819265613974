export default {
  "bullet": "ckt",
  "slice-0": "ckM",
  "slice-1": "ckP",
  "slice-2": "ckl",
  "slice-3": "ckX",
  "slice-4": "ckC",
  "slice-5": "ckk",
  "slice-6": "ckJ",
  "slice-other": "cks",
  "slice-uncategorized": "ckF",
  "label": "ckg",
  "ellipsis": "ckD",
  "rest": "ckV",
  "previous-date-range": "ckL caption"
};
